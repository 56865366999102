import { useEffect, useState } from "react";

const Home = () => {
  const [compositions, setCompositions] = useState(null) 
  useEffect(() => {
    const fetchCompositions = async () => {
      const response = await fetch('https://tft-blitz-api.onrender.com/api/compositions')
      const json = await response.json()
      if(response.ok){
        setCompositions(json)
      }
    };
    fetchCompositions()
  }, []);

  return (
    <div className="home">
      <div className="compositions">
        {compositions && compositions.map((composition) => (
          <p key={composition._id}>{composition.name}</p>
        ))}
      </div>
    </div>
  );
};

export default Home;
